@tailwind base;
@tailwind components;
@tailwind utilities;


.ag-root-wrapper.ag-ltr.ag-layout-normal {
  border: none;
  border-radius: 0;
}

.ag-header {
  background-color: #0a0a0a;
}

.header-cell-even {
  background-color: #0a0a0a;
  /* border-top: 1px solid #d4d4d4; */
  text-align: center;
}

.header-cell-odd {
  background-color: #030712;
  /* border-top: 1px solid #cbd5e1; */
  text-align: center;
}

.market-row-span-even {
  background-color: #171717;
  border-top: 1px solid #444444;
}

.market-row-span-odd {
background-color: #111827;
border-top: 1px solid #444444;
}

.row-span-even {
  background-color: #0a0a0a;
  /* border-top: 1px solid #d4d4d4; */
  opacity: 1;
}

.ag-pinned-left-cols-container .no-border-bottom {
  border-bottom: none;
}

.row-span-odd {
  background-color: #111827;
  /* border-top: 1px solid #cbd5e1; */
  opacity: 1;
}

.group-header-current {
  /* background-color: rgb(0, 0, 98); */
}

.group-header-previous {
  /* background-color: rgb(0, 0, 53); */
  background-color: #1c1917;
}

.group-header-opener {
  /* background-color: rgb(0, 0, 98); */
}

.cell-row-even {
 background-color: #262626;
}

.cell-row-odd {
 background-color: #1f2937;
}

.cell-row-even-current-or-opener {
  /* background-color: #525252; */
  background-color: #0a0a0a; 
}

.cell-row-odd-current-or-opener {
 /* background-color: #4b5563;  */
 background-color: #111827; 
}

/* .ag-cell-focus {
  background-color: black;
} */

.match-cell:focus {
  border-bottom-color: transparent !important;
  border-top-color: transparent !important;
  border-right-color: #444444 !important;
  border-left-color: transparent !important;
}

.match-cell:focus-within {
  border-bottom-color: transparent !important;
  border-top-color: transparent !important;
  border-right-color: #444444 !important;
  border-left-color: transparent !important;
}

.alerts-table-match-cell {
  /* border-right: 1px solid #5c5c5c !important;  */
  border-right: 1px solid var(--ag-header-column-separator-color) !important;
}


.alerts-table-match-cell:focus {
  border-bottom-color: transparent !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
}


.alerts-table-match-cell:focus-within {
  border-bottom-color: transparent !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
}

/**
* Bet log table
*/
.bet-log-table-cell-center {
  display: flex;
  align-items: center;
}

.row-span-even-bet-log {
  background-color: #0a0a0a;
  /* border-top: 1px solid #d4d4d4; */
  opacity: 1;
}

.row-span-odd-bet-log {
  background-color: #262626;
  /* border-top: 1px solid #cbd5e1; */
  opacity: 1;
}

.row-span-even-bet-log:focus-within {
  border-bottom-color: transparent !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
}

.row-span-odd-bet-log:focus-within {
  border-bottom-color: transparent !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
}

.bet-log-table-row.ag-row-hover > .row-span-odd-bet-log {
  /* background-color: #4ade807d; */
  background-color: #030712;
}

.bet-log-table-row.ag-row-hover > .row-span-even-bet-log {
  /* background-color: #4ade807d; */
  background-color: #030712;
}

/**
  * This is to stop the browser's default focus styles for elements that can be focused.
  * because we are using our own focus styles where needed.
  * N.B. In future we should try to assess if this damages accessibility in any way.
  */
@media (max-width: 767px) {
  *:focus {
    outline: none;
  }
}

@media (display-mode: standalone) {
  .hide-in-standalone {
    display: none;
  }
}

@layer base {
  :root {
    --chart-1: 120 100% 50%;
    --chart-2: 135 100% 60%;
    --chart-3: 150 100% 45%;
    --chart-4: 165 100% 55%;
    --chart-5: 180 100% 40%;
    --chart-muted-1: 0 0% 60%;
    --chart-muted-2: 0 0% 65%;
    --chart-muted-3: 0 0% 70%;
    --chart-muted-4: 0 0% 75%;
    --chart-muted-5: 0 0% 80%;
    --chart-muted-6: 0 0% 85%;
    --chart-muted-7: 0 0% 90%;
    --chart-muted-8: 0 0% 95%;
    --chart-muted-9: 0 0% 97%;
  }

  .dark {
    --chart-1: 120 100% 60%;
    --chart-2: 135 100% 70%;
    --chart-3: 150 100% 55%;
    --chart-4: 165 100% 65%;
    --chart-5: 180 100% 50%;
    --chart-muted-1: 0 0% 50%;
    --chart-muted-2: 0 0% 55%;
    --chart-muted-3: 0 0% 60%;
    --chart-muted-4: 0 0% 65%;
    --chart-muted-5: 0 0% 70%;
    --chart-muted-6: 0 0% 75%;
    --chart-muted-7: 0 0% 80%;
    --chart-muted-8: 0 0% 85%;
    --chart-muted-9: 0 0% 90%;
  }
}
